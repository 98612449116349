const CollectionConfig = {
  contractName: 'SheBloomsCollection',
  contractAddress: "0x0fd8a8AEbcdE4b8612c1332DA6462d2de3614cB8",
  openSeaURL: "opensea.io/collection/she-blooms-jdtbrk6b4p",
  //contractAddress: "0xFFbBbCCC990bf3205467F8Fd3f656aa72F86817a",
  stakingAddress: "0x00D393033DC6a029e2A4952fBd6960A712E02C2f",
  tokenAddress: "0x60A0E01A1AFc273533aE03F8693dce52F8cD0C1b"
};

export default CollectionConfig;
